<template>
  <v-card>
    <v-card-title>
      {{ $t("__billings_charge_update") }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                outlined
                dense
                :items="statusOptions"
                item-text="ch"
                item-value="en"
                v-model="form.status"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok">{{
        $t("__confirm")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "UpdateBillingForm",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        status: "paid"
      },
      statusOptions: [
        {
          ch: this.$t("__billings_status_unpaid"),
          en: "unpaid"
        },
        {
          ch: this.$t("__billings_status_paid"),
          en: "paid"
        },
        {
          ch: this.$t("__billings_status_cancelled"),
          en: "cancelled"
        }
      ]
    };
  },
  methods: {
    ok() {
      let data = {
        billingID: this.data.id,
        status: this.form.status
      };
      this.$emit("ok", data);
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          this.form.status = val.status;
        }
      }
    }
  }
};
</script>
